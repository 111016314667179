var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppTable", {
    attrs: { fields: _vm.fields, items: _vm.items },
    scopedSlots: _vm._u([
      {
        key: "cell()",
        fn: function(ref) {
          var left = ref.item
          var top = ref.field.key
          return [
            _c("span", { staticClass: "kills" }, [
              _vm._v(_vm._s(_vm.kills[left][top] || 0))
            ]),
            _c("span", { staticClass: "deaths" }, [
              _vm._v(_vm._s(_vm.kills[top][left] || 0))
            ]),
            _c(
              "span",
              {
                staticClass: "dominance",
                class: _vm.getSignClass(
                  (_vm.kills[left][top] || 0) - (_vm.kills[top][left] || 0)
                )
              },
              [
                _vm._v(
                  _vm._s(
                    _vm._f("signed")(
                      (_vm.kills[left][top] || 0) - (_vm.kills[top][left] || 0)
                    )
                  )
                )
              ]
            )
          ]
        }
      },
      {
        key: "cell(player)",
        fn: function(ref) {
          var index = ref.index
          return [
            _c("ImgCell", [
              _c("div", [
                _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.leftTeam && _vm.leftTeam.players[index].game_name
                    )
                  )
                ]),
                _c("div", [_vm._v(_vm._s(_vm.leftTeam && _vm.leftTeam.name))])
              ])
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }