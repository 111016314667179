var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "kbars" }, [
    _c(
      "div",
      { staticClass: "kbars--legend" },
      [
        _c("TeamCell", {
          attrs: {
            image: _vm.leftTeam.icon_url,
            name: _vm.leftTeam.name,
            variant: "row"
          }
        }),
        _c("TeamCell", {
          attrs: {
            image: _vm.rightTeam.icon_url,
            name: _vm.rightTeam.name,
            variant: "row"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "kbar",
        style: { "--left": _vm.leftKills, "--right": _vm.rightKills }
      },
      [
        _c("span", { staticClass: "kbar--legend" }, [
          _vm._v(_vm._s(_vm.leftKills))
        ]),
        _c("span", { staticClass: "kbar--bar" }, [_vm._v("Kills")]),
        _c("span", { staticClass: "kbar--legend" }, [
          _vm._v(_vm._s(_vm.rightKills))
        ])
      ]
    ),
    _c(
      "div",
      {
        staticClass: "kbar",
        style: { "--left": _vm.leftDeaths, "--right": _vm.rightDeaths }
      },
      [
        _c("span", { staticClass: "kbar--legend" }, [
          _vm._v(_vm._s(_vm.leftDeaths))
        ]),
        _c("span", { staticClass: "kbar--bar" }, [_vm._v("Deaths")]),
        _c("span", { staticClass: "kbar--legend" }, [
          _vm._v(_vm._s(_vm.rightDeaths))
        ])
      ]
    ),
    _c(
      "div",
      {
        staticClass: "kbar",
        style: { "--left": _vm.leftAssists, "--right": _vm.rightAssists }
      },
      [
        _c("span", { staticClass: "kbar--legend" }, [
          _vm._v(_vm._s(_vm.leftAssists))
        ]),
        _c("span", { staticClass: "kbar--bar" }, [_vm._v("Assists")]),
        _c("span", { staticClass: "kbar--legend" }, [
          _vm._v(_vm._s(_vm.rightAssists))
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }